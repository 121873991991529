:root {
	--dark	: #2e3033;
	--darker: #222426;
	--text	: #999690;
	--bright: #e5e0c3;
	--link	: #ffee99;
	--paper	: #d9d3ce;
	--accent: #6688aa;
}


.container {
    width: 75%;
    text-decoration: none !important;
    position: relative;
    margin: auto
}

.row {
    display: flex;
    position: relative;
    background-color: var(--paper);
    box-sizing: border-box;
    flex-direction: row;
    height: 200px;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    margin: 20px;
}

.row:hover {
    background-color: var(--bright);
}

.row .row_image {
    height: 100%;
    width: 160px;
    overflow: hidden;
}

.row .row_image img {
    width: 100%
}

.row .row_text {
    margin-left: 20px;
    text-decoration: none !important;
}

.row .row_content {
    color: var(--dark);
    font-size: 15pt;
}

.link {
    text-decoration: none !important;
}

.row .row_name {
    font-size: 24pt;
    color: var(--darker);
    text-decoration: none !important;
}

.regular_content {
    margin-top: 1rem;
    padding: 0 1rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
}

.page_title {
    padding: 1rem 0;
}

.heading_functional {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


@media only screen and (max-width: 768px) {
    .container {
        width: 100%;
    }
    .row {
        margin-left: 5%;
        margin-right: 5%;
        width: 90%;
    }

    .row .row_text .row_name {
        font-size: 1.3rem;
    }

    .row .row_text .row_tags {
        font-size: 0.75rem;
    }
	
    .row .row_text .row_content {
        font-size: 0.85rem;
    }
}